import React from "react";
import Logo from "../../assets/image/small-logo.png";

import "./style.scss";

export default function Footer() {
  return (
    <div className="pb-5 flex gap-5 justify-center items-center">
      <a
        href="https://twitter.com/HawaiiBurnt"
        target="_blank"
        rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM24.3037 21.1854L24.2513 20.3203C24.0939 18.0782 25.4753 16.0303 27.6612 15.2359C28.4656 14.9534 29.8296 14.9181 30.7214 15.1653C31.0712 15.2712 31.7357 15.6243 32.2078 15.9421L33.0647 16.5247L34.009 16.2245C34.5336 16.0656 35.2331 15.8008 35.5478 15.6243C35.8451 15.4654 36.1074 15.3771 36.1074 15.4301C36.1074 15.7302 35.4604 16.7542 34.9183 17.3191C34.1839 18.1135 34.3937 18.1842 35.8801 17.6545C36.7719 17.3544 36.7894 17.3544 36.6145 17.6898C36.5096 17.8664 35.9675 18.4843 35.3905 19.0492C34.4112 20.0202 34.3587 20.1261 34.3587 20.9382C34.3587 22.1917 33.7642 24.8045 33.1696 26.2345C32.0679 28.9179 29.7072 31.6896 27.3464 33.0843C24.0239 35.0439 19.5997 35.5382 15.875 34.3907C14.6334 34.0023 12.5 33.0137 12.5 32.8371C12.5 32.7842 13.147 32.7136 13.9339 32.6959C15.5777 32.6606 17.2215 32.2016 18.6204 31.3895L19.5647 30.8246L18.4805 30.4538C16.9417 29.9242 15.5602 28.7061 15.2105 27.5585C15.1056 27.1878 15.1405 27.1701 16.1198 27.1701L17.1341 27.1525L16.2772 26.7464C15.2629 26.2345 14.3361 25.3694 13.8815 24.4867C13.5492 23.8512 13.1295 22.2446 13.2519 22.121C13.2869 22.0681 13.6541 22.174 14.0738 22.3152C15.2804 22.7566 15.4378 22.6507 14.7383 21.9092C13.4268 20.5675 13.0246 18.5725 13.6541 16.6835L13.9514 15.8361L15.1056 16.9837C17.4663 19.2964 20.2467 20.6734 23.4294 21.0794L24.3037 21.1854Z"
            fill="white"
          />
        </svg>
      </a>
      <a
        href="https://t.me/HawaiiBurnt"
        target="_blank"
        rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24ZM19.6 35L20.0083 28.8823L20.008 28.882L31.1369 18.839C31.6253 18.4055 31.0303 18.1941 30.3819 18.5873L16.6473 27.2523L10.7147 25.4007C9.4335 25.0084 9.4243 24.128 11.0023 23.4951L34.1203 14.5809C35.1762 14.1015 36.1953 14.8345 35.7922 16.4505L31.8552 35.0031C31.5803 36.3215 30.7837 36.6368 29.68 36.0278L23.6827 31.5969L20.8 34.4C20.7909 34.4088 20.7819 34.4176 20.7729 34.4264C20.4505 34.7403 20.1837 35 19.6 35Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
  );
}
